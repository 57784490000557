import axios from "axios"
import {
  MDBAlert,
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBContainer,
  MDBInput,
} from "mdbreact"
import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { registerOrder } from "../../actions/orderActions"
import AsyncSelect from "react-select/async"
import { Link } from "react-router-dom"
import DateFnsUtils from "@date-io/date-fns"
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import FormContainer from "../../components/FormContainer"
import { ORDER_REGISTER_RESET } from "../../constants/orderConstants"
import { USER_LOGOUT } from "../../constants/userConstants"
import { listCategories } from "../../actions/categoryActions"

const OrderCreateScreen = ({ history, location }) => {
  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const orderRegister = useSelector((state) => state.orderRegister)
  const { success, error: createError } = orderRegister

  const categoriesList = useSelector((state) => state.categoriesList)
  const { categories } = categoriesList

  const [category, setCategory] = useState("")
  const [error, setError] = useState("")
  const [product, setProduct] = useState([])
  const [products, setProducts] = useState([])
  const [customer, setCustomer] = useState()
  const [physicians, setPhysicians] = useState([])
  const [provider, setProvider] = useState("")
  const [loading, setLoading] = useState(false)
  const [physician, setPhysician] = useState()
  const [startTime, setStartTime] = useState(null)
  const [endTime, setEndTime] = useState(null)
  const [subProduct, setSubProduct] = useState("")
  const [filepath, setFilepath] = useState("")
  const [assignedLawyers, setAssignedLawyers] = useState([])
  const [selectLawfirm, setSelectLawfirm] = useState("")

  useEffect(() => {
    if (!userInfo) {
      history.push("/")
    }
    if (success) {
      setTimeout(() => {
        dispatch({ type: ORDER_REGISTER_RESET })
        if (userInfo.role !== "Admin") {
          history.push("/orders")
        } else {
          history.push("/admin/order")
        }
      }, 1000)
    }
    dispatch(listCategories())
    if (userInfo && userInfo.role === "User") {
      setSelectLawfirm(userInfo.lawfirm._id)
    }
    if (userInfo && userInfo.role === "Law Firm Admin") {
      setSelectLawfirm(userInfo.lawfirm._id)
    }
    if (createError && createError === "Token Expired") {
      dispatch({ type: USER_LOGOUT })
    }
  }, [dispatch, history, userInfo, success, createError])

  async function loadLawfirms(value) {
    if (value.length > 2) {
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      }
      const prov = await axios.get(`/api/lawfirm/search/${value}`, config)
      return prov.data
    }
  }

  async function categoryChange(cat) {
    if (cat !== "") {
      setLoading(true)
      const prods = await axios.get(`/api/product/category/${cat}`)
      setProducts(prods.data)
      setCategory(cat)
      setLoading(false)
      if (cat.includes("Medical Records")) {
        setSubProduct("Clinical Notes")
      } else {
        setSubProduct("Appointment")
      }
    }
  }
  const productChange = (prod, val) => {
    // if (category === "IRO" && val === "Medical Records") {
    //   setSubProduct("Clinical Notes")
    // } else if (category === "IRO" && val !== "Medical Records") {
    //   setSubProduct("Appointment")
    // } else {
    //   setSubProduct("")
    // }
    // if (category.includes("Medical Records")) {
    //   setSubProduct("Clinical Notes")
    // }
    setProduct(prod)
  }

  async function searchCustomer(value) {
    if (value.length > 2) {
      setLoading(true)
      let cust
      if (userInfo.role === "Admin" && selectLawfirm) {
        cust = await axios.get(
          `/api/customers/search/${value}/${selectLawfirm}`
        )
      } else {
        cust = await axios.get(
          `/api/customers/search/${value}/${userInfo.lawfirm._id}`
        )
      }
      setLoading(false)
      return cust.data
    }
  }

  async function searchProvider(value) {
    if (value.length > 2) {
      setLoading(true)
      const prov = await axios.get(`/api/providers/search/${value}`)
      setLoading(false)
      return prov.data
    }
  }

  async function searchLawyer(value) {
    if (value.length > 2) {
      let lawfirms
      if (userInfo.role === "Admin") {
        lawfirms = selectLawfirm
      } else {
        lawfirms = userInfo.lawfirm._id
      }
      if (lawfirms) {
        const prov = await axios.get(`/api/users/search/${lawfirms}/${value}`)
        return prov.data
      }
    }
  }

  async function providerChange(value) {
    const user = value.split(",")[0]
    const provider = value.split(",")[1]
    setLoading(true)
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const phys = await axios.get(`/api/physician/provider/${user}`, config)
    setPhysicians(phys.data)
    setProvider(provider)
    setLoading(false)
  }

  const customerChange = (value) => {
    if (value.length > 0) {
      setCustomer(value)
    }
  }
  const subProductChange = (value) => {
    if (value.length > 0) {
      setSubProduct(value)
    }
  }

  const lawyerAdded = (e) => {
    const temp = []
    e.forEach((lawyers) => {
      temp.push(lawyers._id)
    })
    if (!temp.includes(userInfo._id) && userInfo.role === "Lawyer") {
      temp.push(userInfo._id)
    }
    setAssignedLawyers(temp)
  }

  const uploadFileHandler = async (e) => {
    setLoading(true)
    const file = e.target.files[0]
    const formData = new FormData()
    formData.append("docs", file)
    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
      const { data } = await axios.post(`/api/upload`, formData, config)
      setFilepath(data)
    } catch (error) {
      alert("File Upload Error")
    }
    setLoading(false)
  }

  const submitHandler = (e) => {
    e.preventDefault()
    e.target.className += " was-validated"
    if (
      customer &&
      category !== "" &&
      product.length > 0 &&
      provider !== "" &&
      filepath !== ""
    ) {
      if (subProduct === "Appointment") {
        if (startTime && endTime) {
          dispatch(
            registerOrder({
              customer,
              product,
              provider,
              totalAmount: 0,
              physician,
              startTime,
              endTime,
              added_user: userInfo._id,
              status: "Pending",
              lawfirm: selectLawfirm,
              filepath,
              assignedLawyers,
            })
          )
          e.target.className = "needs-validation"
          // history.push("/orders")
        } else {
          setError("Please complete all required fields")
        }
      } else {
        dispatch(
          registerOrder({
            customer,
            product,
            provider,
            totalAmount: 0,
            status: "Active",
            added_user: userInfo._id,
            lawfirm: selectLawfirm,
            filepath,
            assignedLawyers,
          })
        )
        // history.push("/orders")
      }
      e.target.className = "needs-validation"
    } else {
      setError("Please complete all required fields")
    }
  }

  return (
    <MDBContainer className="py-5">
      <FormContainer>
        <MDBCard>
          <MDBCardHeader>
            <h2>Create New Order</h2>
          </MDBCardHeader>
          <MDBCardBody>
            {error && <MDBAlert color="danger">{error}</MDBAlert>}
            <form
              onSubmit={submitHandler}
              noValidate
              className="needs-validation"
            >
              {userInfo && userInfo.role === "Admin" && (
                <div className="form-group">
                  <label htmlFor="">Select Lawfirm</label>
                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    getOptionLabel={(e) =>
                      `${e.name} ${e.branch ? "- " + e.branch : ""}`
                    }
                    getOptionValue={(e) => e._id}
                    className="basic-single"
                    loadOptions={loadLawfirms}
                    onChange={(e) => setSelectLawfirm(e._id)}
                    placeholder="Type 3 characters for dropdown to appear"
                  />
                  <div>
                    <Link to="/admin/lawfirm/create" target="_blank">
                      <span style={{ color: "#55622b", cursor: "pointer" }}>
                        Or, Create New Lawfirm
                      </span>
                    </Link>
                  </div>
                </div>
              )}
              {userInfo && (
                <div className="form-group">
                  <label htmlFor="">Select Lawyers</label>
                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    getOptionLabel={(e) => `${e.name} - ${e.email}`}
                    getOptionValue={(e) => e._id}
                    className="basic-single"
                    loadOptions={searchLawyer}
                    onChange={(e) => {
                      lawyerAdded(e)
                    }}
                    placeholder="Type 3 characters for dropdown to appear"
                    isMulti
                  />
                  {userInfo.role !== "User" && (
                    <div>
                      <Link to="/lawfirm/lawyer/create" target="_blank">
                        <span style={{ color: "#55622b", cursor: "pointer" }}>
                          Or, Create New Lawyer
                        </span>
                      </Link>
                    </div>
                  )}
                </div>
              )}
              <div className="form-group">
                <label htmlFor="">Select Participant</label>
                <AsyncSelect
                  cacheOptions
                  defaultOptions
                  getOptionLabel={(e) => `${e.name} - ${e.email}`}
                  getOptionValue={(e) => e._id}
                  className="basic-single"
                  loadOptions={searchCustomer}
                  onChange={(e) => customerChange(e._id)}
                  placeholder="Type 3 characters for dropdown to appear"
                />
                <div>
                  <Link to="/customer/create" target="_blank">
                    <span style={{ color: "#55622b", cursor: "pointer" }}>
                      Or, Create New Participant
                    </span>
                  </Link>
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="">Select Category</label>
                <select
                  className="browser-default custom-select"
                  onChange={(e) => categoryChange(e.target.value)}
                  disabled={loading}
                  required
                >
                  <option value=""></option>
                  {categories &&
                    categories.map((cat) => (
                      <option value={cat.name} key={cat._id}>
                        {cat.name}
                      </option>
                    ))}
                </select>
              </div>
              {products && (
                <>
                  <div className="form-group">
                    <label htmlFor="">Select Product</label>
                    <select
                      className="browser-default custom-select"
                      onChange={(e) =>
                        productChange(
                          e.target.value,
                          e.target.selectedOptions[0].text
                        )
                      }
                      required
                    >
                      <option value=""></option>
                      {products.map((product, proindex) => (
                        <option value={product._id} key={proindex}>
                          {product.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </>
              )}
              <div className="form-group">
                <label htmlFor="">Select Sub-Product</label>
                <select
                  className="browser-default custom-select"
                  name="subProduct"
                  onChange={(e) => subProductChange(e.target.value)}
                  value={subProduct}
                  required
                >
                  <option></option>
                  <option value="Appointment">Appointment</option>
                  <option value="Clinical Notes">Clinical Notes</option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="">Select Provider</label>
                <AsyncSelect
                  cacheOptions
                  defaultOptions
                  getOptionLabel={(e) =>
                    `${e.name} ${e.branch ? "- " + e.branch : ""}`
                  }
                  getOptionValue={(e) => `${e.user},${e._id}`}
                  className="basic-single"
                  loadOptions={searchProvider}
                  onChange={(e) => providerChange(`${e.user},${e._id}`)}
                  placeholder="Type 3 characters for dropdown to appear"
                />
                <div>
                  <Link to="/provider/create" target="_blank">
                    <span style={{ color: "#55622b", cursor: "pointer" }}>
                      Or, Create New Provider
                    </span>
                  </Link>
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="">Upload Authority/Consent</label>
                <input
                  type="file"
                  label="Choose File"
                  className="form-control"
                  onChange={uploadFileHandler}
                  required
                />
              </div>
              <MDBInput type="hidden" value={filepath} />
              {subProduct === "Appointment" && (
                <>
                  <div className="form-group">
                    <label htmlFor="">Request Appointments</label>

                    <select
                      className="browser-default custom-select"
                      onChange={(e) => setPhysician(e.target.value)}
                    >
                      <option></option>
                      {physicians.map((physician, aindex) => (
                        <option key={aindex} value={physician._id}>
                          {physician.name}
                        </option>
                      ))}
                    </select>
                    <Link to="/physician/create" target="_blank">
                      <span style={{ color: "#55622b", cursor: "pointer" }}>
                        Or, Create New Physician
                      </span>
                    </Link>
                    <br />
                    <div className="form-group">
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DateTimePicker
                          label="Start Time"
                          onChange={(date) => setStartTime(date)}
                          disablePast
                          value={startTime}
                          className="form-control"
                          minutesStep="5"
                        />
                      </MuiPickersUtilsProvider>
                    </div>
                    <br />
                    <div className="form-group">
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DateTimePicker
                          label="End Time"
                          onChange={(date) => setEndTime(date)}
                          disablePast
                          value={endTime}
                          className="form-control"
                          minutesStep="5"
                        />
                      </MuiPickersUtilsProvider>
                    </div>
                  </div>
                </>
              )}

              <br />
              <MDBBtn
                type="submit"
                color="blue-grey lighten-3"
                disabled={loading}
              >
                Submit
              </MDBBtn>
              <MDBBtn
                onClick={() => history.goBack()}
                color="blue-grey lighten-3"
              >
                Back
              </MDBBtn>
            </form>
          </MDBCardBody>
        </MDBCard>
      </FormContainer>
    </MDBContainer>
  )
}

export default OrderCreateScreen
